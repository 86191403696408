import React from 'react';
import WatchCategoryPercentageBox from './WatchCategoryPercentageBox';

const TranslationSection = ({ languagePageDetails, watchCategoryPercentageLists }) => {
  return (
    <div className="wrapper wrapper-xl" style={{ color: 'white' }}>
      <div className="wrapper wrapper-lg">
        <div className="translation-container">
          <div className="translation-detail">
            <h3>Translation Progress</h3>
            <p style={{ color: 'white', marginTop: 20 }}>
              {languagePageDetails?.nodes[0].data.contactUsDescription[0].text}
            </p>
          </div>
          <div className="translation-percentage-container">
            {watchCategoryPercentageLists?.map((item) => (
              <WatchCategoryPercentageBox title={item.title} percent={item.percent} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranslationSection;
