import React from 'react';

const SeasonBox = ({ title, percent }) => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(244, 245, 247, 0.18)',
        width: '45%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '141px',
        paddingLeft: '25px',
        marginLeft: 10,
        marginTop: '10px',
      }}
    >
      <h6 style={{ textTransform: 'uppercase', overflowWrap: 'break-word', fontSize: '14px' }}>{title}</h6>
      <h1 style={{ lineHeight: '30px', color: '#FFBB0D' }}>{percent}%</h1>
    </div>
  );
};

export default SeasonBox;
