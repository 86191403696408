import { graphql, Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import TranslationSection from '../../components/Language/TranslationSection';
import ArrowLeft from '../../images/icon-arrow-left.svg';
import WatchItem from '../../components/Watch/WatchItem';
import { normalizeWatchCategories } from '../../utils/watch';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import { getCountryFlagImageLink } from '../../utils/language';
import Donation from '../../components/Donation';

const categories = {
  'season-1': 'Season 1',
  'season-2': 'Season 2',
  'season-3': 'Season 3',
  'the-7-churches-of-revelation': '7 Churches of Revelation',
  'the-great-controversy': 'The Great Controversy',
};
const LanguagesPage = (props) => {
  const {
    data: {
      bgContact,
      totalWatchData,
      patreonThumb,
      languagePageDetails,
      seasonOneEpisodesTotalCount,
      seasonTwoEpisodesTotalCount,
      seasonThreeEpisodesTotalCount,
      churchesOfRevelationEpisodesTotalCount,
      greatControversyEpisodesTotalCount,
    },
    pageContext: { language, languageCode, nativeLanguageTitle, flagCode, languageTitle },
  } = props;

  const [showDonationBox, setShowDonationBox] = useState(false);

  const normalizedWatchData = normalizeWatchCategories(totalWatchData?.nodes || [], true, true);
  const [selectedSeason, setSelectedSeason] = React.useState('season-1');

  let requiredData = [];
  const selectedCategoryDetails = normalizedWatchData['series'];

  React.useEffect(() => {
    if (selectedCategoryDetails) {
      for (const item of Object.keys(categories)) {
        if (Object.keys(selectedCategoryDetails).includes(item)) {
          setSelectedSeason(item);
          break;
        }
      }
    }
  }, []);

  const calculatePercentage = (total, value) => {
    if (value) {
      return (value / total) * 100;
    }
    return 0;
  };

  requiredData = selectedCategoryDetails ? selectedCategoryDetails[selectedSeason] : [];

  let bgImage = bgContact;

  const categoriesTotalCount = {
    'season-1': seasonOneEpisodesTotalCount,
    'season-2': seasonTwoEpisodesTotalCount,
    'season-3': seasonThreeEpisodesTotalCount,
    'the-7-churches-of-revelation': churchesOfRevelationEpisodesTotalCount,
    'the-great-controversy': greatControversyEpisodesTotalCount,
  };
  const watchCategoryPercentageLists = Object.keys(categories).map((category) => {
    return {
      title: categories[category],
      percent: Math.floor(
        calculatePercentage(categoriesTotalCount[category].totalCount, selectedCategoryDetails[category]?.length)
      ),
    };
  });

  return (
    <>
      <BodyClassName className="body-dark page-watch page-watch--category">
        <DefaultLayoutComponent>
          <div className="">
            <BackgroundImage
              Tag="section"
              className="page-banner bgImage bg-pos-center-top"
              fluid={bgImage.childImageSharp.fluid}
              preserveStackingContext
            >
              <div className="overlay" />
              <div className="wrapper wrapper-lg pd-x-sm la-pn">
                <div className="breadcrumb pd-t-0 bc-bg-transparent pd-x-sm watch-bc">
                  <div className="bc-holder" onClick={() => navigate('/language')}>
                    <img src={ArrowLeft} alt="" />
                    <p>Back</p>
                  </div>
                </div>
              </div>
              <div className="wrapper wrapper-xl pd-x-md">
                <div className="pd-x-md pb-container ">
                  <div className="d-flex" style={{ justifyContent: 'center' }}>
                    <div>
                      <img
                        src={getCountryFlagImageLink(flagCode)}
                        style={{ verticalAlign: 'bottom', marginRight: 16 }}
                      />
                    </div>
                    <div>
                      <h3 className="pb-title" style={{ textAlign: 'left' }}>
                        {' '}
                        {nativeLanguageTitle}
                      </h3>
                      <h3 className="pb-title" style={{ fontSize: 18, textAlign: 'left' }}>
                        {languageTitle}
                      </h3>
                    </div>
                  </div>
                  <div
                    className="filter-categories"
                    style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}
                  >
                    <ul className="fil_cat-list d-flex">
                      {selectedCategoryDetails &&
                        Object.keys(categories).map(
                          (item, index) =>
                            selectedCategoryDetails[item] && (
                              <li
                                key={`categoryName-${index}`}
                                className={`fil_cat-item  mg-r-5 mg-b-5 ${item === selectedSeason ? 'active' : ''}`}
                                onClick={() => setSelectedSeason(item)}
                              >
                                {categories[item]}
                              </li>
                            )
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            </BackgroundImage>
            {selectedCategoryDetails && (
              <section className="wrapper-xl wrapper categories-watch categories-filters bg-light categories-watch--detail pb-container">
                <div className="wrapper wrapper-lg pd-x-sm pd-t-half">
                  <h5
                    className="pd-x-sm pb-title"
                    style={{ color: '#0A0A0A', textTransform: 'uppercase', fontSize: '21px' }}
                  >
                    {categories[selectedSeason]}
                  </h5>
                  <div className="cat_watch-list">
                    <div className="cat_watch-row">
                      <div className="cat_watch-body">
                        <div className="cat_watch-body-list">
                          <div className="cat_watch-item">
                            <div className="watch_item-row columns-5 d-flex">
                              {!!requiredData?.length &&
                                requiredData?.map((episodeItem, idx) => (
                                  <WatchItem
                                    key={idx}
                                    data={episodeItem}
                                    className="cols watch_item-list "
                                    titleColor="#0A0A0A"
                                    showPlayIcon
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section>
              <div className="wrapper pd-x-md">
                <TranslationSection
                  languagePageDetails={languagePageDetails}
                  watchCategoryPercentageLists={watchCategoryPercentageLists}
                />
              </div>
            </section>

            <div className="body-light page-give">
              <div>
                <section className="give-container bg-dark pd-0">
                  <div className="wrapper wrapper-xl">
                    <div className="section bg-light give pd-b-0">
                      <div className="wrapper wrapper-lg ">
                        <h4 className="mg-b-0">Donate</h4>
                        <p className="p-xl" style={{ paddingLeft: '5%', paddingRight: '5%', textAlign: 'center' }}>
                          {' '}
                          Lineage began as a seed. A small idea packed with big potential. True to its nature, that seed
                          has grown beyond our wildest expectations and has reached people in ways we could never have
                          imagined.
                        </p>
                        {showDonationBox ? (
                          <Donation patreonThumb={patreonThumb} isStandardCheckOut={true} />
                        ) : (
                          <div className="wrapper-lg wrapper-ul" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                            <ul className="tab-head give-head method-donation d-flex">
                              <li
                                className={`btn btn-sm active`}
                                style={{ margin: 'auto', cursor: 'pointer' }}
                                onClick={() => setShowDonationBox(true)}
                              >
                                <a to="">{`GIVE NOW`}</a>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </DefaultLayoutComponent>
      </BodyClassName>
    </>
  );
};

export const query = graphql`
  query LanguagesPageDetailQuery($language: String!, $languageCategory: String!) {
    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    languagePageDetails: allPrismicLanguagePage {
      nodes {
        data {
          contactUsDescription: contact_us_description {
            text
          }
          pageDescription: page_description {
            text
          }
        }
      }
    }
    seasonOneEpisodesTotalCount: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["season-1"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
    ) {
      totalCount
    }
    seasonTwoEpisodesTotalCount: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { eq: "season-2" } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
    ) {
      totalCount
    }
    seasonThreeEpisodesTotalCount: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { eq: "season-3" } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
    ) {
      totalCount
    }
    churchesOfRevelationEpisodesTotalCount: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { eq: "the-7-churches-of-revelation" } } } }
          language: { eq: "English" }
        }
      }
    ) {
      totalCount
    }
    greatControversyEpisodesTotalCount: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { eq: "the-great-controversy" } } } }
          language: { eq: "English" }
        }
      }
    ) {
      totalCount
    }
    totalWatchData: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { uid: { eq: $language } } } }
          language: { eq: $languageCategory }
        }
      }
      sort: { fields: data___priority, order: ASC }
    ) {
      nodes {
        data {
          categories {
            episodeCategory: episodes_category {
              categoryType: slug
            }
          }
          description {
            text
          }
          language
          priority
          videoDuration: video_duration {
            text
          }
          youtubeId: youtube_id {
            text
          }
          bts
          subPeriods: sub_period {
            subPeriod: sub_period1 {
              slug
            }
          }
          people {
            name: people1 {
              authorName: slug
            }
          }
          places {
            name: places1 {
              placeName: slug
            }
          }
          relatedArticles: related_articles {
            articles {
              slug
            }
          }
          title {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
        }
        slugs
        uid
        url
      }
    }
    patreonThumb: file(relativePath: { eq: "patreon-thumb.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default LanguagesPage;
